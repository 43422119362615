import React from "react"

import Seo from "../shared_components/seo"
import GoFundMe from "../layout/gofundme"

import "@fortawesome/fontawesome-free/css/all.css"

const email = "opensource@bitframe.org"
const EmailLink = () => { return <a href={"mailto:" + email}>{email}</a> }
const formAction = process.env.NODE_ENV === "development" ? "http://127.0.0.1:9016" : process.env.BASE_URL + "/donateform"

const DonatePage = () => {
  return (
    <div>
      <Seo />
      <h1>Donations to Fund Open Source Developers in Damaging Litigation</h1>

      <p>Please ready our <a href="/">Open Letter to the Open Source Industry</a> if you haven't already!</p>

      <h2>April 2024 Update!</h2>

      <p>As of April 16th, 2024 the lawsuit was discontinued by the claimant. We are deeply appreciative of all of our supporters who shared this message and/or donated funds. We will continue to accept donations until final costs of the case are determined.</p>

      <h2>How can I donate?</h2>

      <div className="donate-option-cards">
        <div className="donate-option-card card-gofundme">
          <a href="https://www.gofundme.com/f/open-source-defense-fund">
            <div style={{marginTop: '1em'}}>
              <GoFundMe />
            </div>
            <div style={{fontSize: '0.6em', marginTop: '1em'}}>For donations up to $9,000.</div>
          </a>
        </div>
        <div className="donate-option-card" style={{background: '#bcf'}}>
          <h1 style={{color: '#333'}}>Cryptocurrency</h1>
          <div>
            <span><img width="50" style={{margin: '2px'}} alt="Bitcoin" src="/img/bitcoin-icon.svg" /></span>
            <span><img width="50" style={{margin: '2px'}} alt="eCash" src="/img/ecash-circle-icon-blue-gradient.svg" /></span>
            <span><img width="50" style={{margin: '2px'}} alt="Bitcoin Cash" src="/img/bitcoin-cash-icon.svg" /></span>
          </div>
          <div style={{fontSize: '1em', marginTop: '1em'}}>For donations up to $9,000.</div>
          <form action={formAction} method="POST" className="donate-form">
            <div>
              <label><span>Name <span style={{color: 'red'}}>*</span></span><input name="name" type="text" minlength="5" required /></label>
            </div>
            <div>
              <label><span>Email <span style={{color: 'red'}}>*</span></span><input name="email" type="email" required /></label>
            </div>
            <div className="form-hp">
              <label><span>Comment</span><input type="text" name="comment" value="none" required /></label>
            </div>
            <input type="submit" value="See Donation Address" className="donate-submit" />
      <p style={{fontSize: '0.7em'}}>You will be presented with a payment address upon submission of your name and contact details. Due to anti-money laundery regulations, we must share this information with our lawyers since we need to take reasonable steps to establish the source of funds used to pay them. Your information will not be shared with any other parties.</p>
          </form>
        </div>
        <div className="donate-option-card card-getintouch">
          <h1 style={{color: '#333'}}>Get in touch!</h1>
          <div>
            <span><img width="50" style={{margin: '2px'}} alt="Ethereum" src="/img/eth-diamond-purple.svg" /></span>
            <span><img width="50" style={{margin: '2px'}} alt="USDCoin" src="/img/usdc-icon.svg" /></span>
          </div>
          <div style={{fontSize: '0.5em', margin: '1em 0'}}>
            If you would like to:
            <ul style={{margin: '0', padding: '0', textAlign: 'left'}}>
              <li>Donate Ethereum or USDC</li>
              <li>Donate more than $9,000</li>
              <li>Get questions answered</li>
              <li>Arrange something else</li>
            </ul>
          </div>
          <div style={{fontSize: '0.6em'}}>
            <EmailLink />
          </div>
        </div>
      </div>

      <p style={{fontSize: '0.8em'}}>Disclaimer: The English court can, in some circumstances, order non-parties to litigation to pay the legal costs of successful parties, including where the non-party has funded or controlled the litigation. We do not believe those circumstances apply here because donors will have no direct interest in the outcome of this litigation and no say in or control over the case. If you would like more information, please get in touch or seek legal advice.</p>

      <h2>How will my donation be spent?</h2>

      <p>There are multiple groups of developers defending this lawsuit and each group has obtained separate legal representation. This means different sets of bills. Bitframe LLC is collecting donations in order to support all of the developer groups involved.</p>

      <div>Donations will be spent in this equitable manner:</div>
      <ol>
        <li>Developers who lack sponsorship to support their legal defense will be offered funding first.</li>
        <li>Further remaining funds will be provided as additional legal retainers to fill in gaps that legal teams may require to defend developers to the lawsuit’s conclusion, after the full capability of any sponsorship is accounted for.</li>
        <li>Should funds remain at the end of the trial, Bitframe LLC will look to establish a more permanent foundation or similar non-profit using those funds to defend open source developers against lawsuits. If not enough funds remain to make a new organization compelling, we will donate the remainder to an existing open source non-profit at our discretion, such as the Linux Foundation, Free Software Foundation, Apache Foundation, etc. Donations unfortunately will not be refundable.</li>
      </ol>

      <h2>How do you choose who gets funded as a developer?</h2>

      <p>Bitframe LLC will focus funding on defenses for individuals who have contributed to open source projects. No situation is the same, so we will apply due diligence and review the case for each individual. Those with a history of verifiable collaboration will be the most likely to receive funding.</p>

      <h2>Why do some developers have sponsorships but others do not?</h2>

      <p>Some developers managed to secure private sponsorships to fund their defense. The other developers haven’t been so lucky. Either way, those sponsorships are limited and not able to fund their defense in its entirety.</p>

      <h2>Who makes decisions regarding the donations?</h2>

      <p>Bitframe LLC will use its discretion to apply the principles described above. Jason Cox, one of the developers named in the lawsuit, is the owner of Bitframe LLC and has been an avid believer in open source software. Bitframe LLC is working closely with the other developer defendants to ensure their defense needs are met.</p>

      <p>Please note that donations do not create a trust, either explicitly or implied. Use of donations proceeds is entirely at the discretion of Bitframe LLC.</p>

      <h2>Are these donations tax deductible?</h2>

      <p>Not at this time, but that may change in the future. See above for details.</p>


      <p>Want to chat about details before donating or have other questions? Contact us: <EmailLink/></p>
    </div>
  )
}

export default DonatePage
